import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/share-[id]": [~18],
		"/[chain=chain_name]/portfolio": [5,[2,3]],
		"/[chain=chain_name]/portfolio/accounts": [6,[2,3]],
		"/[chain=chain_name]/portfolio/activities": [7,[2,3]],
		"/[chain=chain_name]/portfolio/deposits-withdrawals": [8,[2,3]],
		"/[chain=chain_name]/portfolio/margin-health": [10,[2,3]],
		"/[chain=chain_name]/portfolio/margin": [9,[2,3]],
		"/[chain=chain_name]/portfolio/markets": [11,[2,3]],
		"/[chain=chain_name]/portfolio/open-orders": [12,[2,3]],
		"/[chain=chain_name]/portfolio/positions": [13,[2,3]],
		"/[chain=chain_name]/portfolio/trade-history": [14,[2,3]],
		"/[chain=chain_name]/portfolio/wallet": [15,[2,3]],
		"/[chain=chain_name]/trade/[market=market_name]": [16,[2]],
		"/[chain=chain_name]/trade/[market=market_name]/charts-mobile": [17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';